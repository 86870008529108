// client/src/components/LoginPage.js
import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, Grid, Snackbar, Alert } from '@mui/material';
import { fetchApi } from '../services/api';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDHWKkwqrRNxxp8A1YnnklSRtd2B4HdlIs",
  authDomain: "soundsidedesign.firebaseapp.com",
  projectId: "soundsidedesign",
  storageBucket: "soundsidedesign.firebasestorage.app",
  messagingSenderId: "966897524659",
  appId: "1:966897524659:web:1224ab2b9565a06da635c6",
  measurementId: "G-CC88FF8LT3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

if (process.env.NODE_ENV === 'development') {
  if (typeof auth.useEmulator === 'function') {
    auth.useEmulator('http://localhost:9099');
    console.log('Using Firebase Auth emulator at http://localhost:9099');
  } else {
    console.warn('Firebase Auth emulator not supported in this SDK version');
  }
}

const googleProvider = new GoogleAuthProvider();

export default function LoginPage() {
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });

  const showNotification = (message, severity = 'info') => {
    setNotification({ open: true, message, severity });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  async function handleGoogleLogin() {
    try {
      console.log('Starting Google Login...');
      const result = await signInWithPopup(auth, googleProvider);
      const idToken = await result.user.getIdToken();
      console.log('ID Token:', idToken);
      if (!idToken) {
        throw new Error('No ID token retrieved');
      }
      const resp = await fetchApi('/api/login', 'POST', { idToken }, { credentials: 'include' });
      if (resp.success) {
        sessionStorage.setItem('jwt', resp.token);
        sessionStorage.setItem('userId', result.user.uid); // Store UID here
        showNotification('Logged in successfully!', 'success');
      } else {
        showNotification(`Login failed: ${JSON.stringify(resp)}`, 'error');
      }
    } catch (err) {
      console.error('Login error:', err);
      showNotification(`Login error: ${err.message}`, 'error');
    }
  }

  return (
    <Paper sx={{ padding: '20px', backgroundColor: '#1e1e1e', color: '#e0e0e0' }}>
      <Typography variant="h5" gutterBottom sx={{ color: '#e0e0e0' }}>Login</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleGoogleLogin} fullWidth>
            Sign in with Google
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}