import React, { useState, useEffect } from 'react';
import { Paper, Typography, Button, TextField, Select, MenuItem, FormControlLabel, Checkbox, Grid, Tooltip, Alert, Snackbar } from '@mui/material';
import { fetchApi } from '../services/api';

export default function VideoPage() {
  const [model, setModel] = useState('T2V-01');
  const [prompt, setPrompt] = useState('');
  const [promptOptimizer, setPromptOptimizer] = useState(true);
  const [firstFrameUrl, setFirstFrameUrl] = useState('');
  const [subjectRef, setSubjectRef] = useState('');
  const [isGeneratingPrompt, setIsGeneratingPrompt] = useState(false);
  const [grokImage, setGrokImage] = useState(null); // New state for Grok image
  const [videoTasks, setVideoTasks] = useState([]);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });

  const showNotification = (message, severity = 'info') => {
    setNotification({ open: true, message, severity });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  async function createVideoTask() {
    if (!prompt && !firstFrameUrl && !subjectRef) {
      showNotification("Please provide a prompt, first frame image, or subject reference", 'warning');
      return;
    }

    // Check if prompt exceeds 2000 characters
    if (prompt && prompt.length > 2000) {
      showNotification("Prompt must be less than 2000 characters", 'warning');
      return;
    }

    // Validate image parameters based on model
    if (firstFrameUrl && !['I2V-01', 'I2V-01-live'].includes(model)) {
      showNotification(`First frame image is only supported for I2V-01 and I2V-01-live models, not ${model}`, 'warning');
      return;
    }

    if (subjectRef && model !== 'S2V-01') {
      showNotification(`Subject reference is only supported for S2V-01 model, not ${model}`, 'warning');
      return;
    }

    const body = {
      model,
      prompt: prompt ? prompt.trim() : undefined,
      prompt_optimizer: promptOptimizer,
      first_frame_image: firstFrameUrl || undefined,
      subject_reference: subjectRef ? [{ type: 'character', image: subjectRef }] : undefined
    };
    console.log('Creating video task with body:', body);

    try {
      const resp = await fetchApi('/api/video', 'POST', body, {
        headers: {
          'x-user-id': sessionStorage.getItem('userId') || 'dummy-user',
          'Content-Type': 'application/json'
        }
      });
      showNotification(`Created new video task. ID=${resp.id}, status=${resp.status}`, 'success');
      loadVideoTasks();
    } catch (err) {
      showNotification(`Error creating video task: ${err.message}`, 'error');
    }
  }

  async function loadVideoTasks() {
    try {
      const userId = sessionStorage.getItem('userId') || 'dummy-user';
      const tasks = await fetchApi(`/api/video?userId=${encodeURIComponent(userId)}`, 'GET', null, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      tasks.sort((a, b) => b.createdAt - a.createdAt);
      setVideoTasks(tasks);
    } catch (err) {
      showNotification(`Error loading video tasks: ${err.message}`, 'error');
    }
  }

  async function deleteVideo(id) {
    if (!window.confirm("Are you sure you want to delete this video record?")) return;
    try {
      await fetchApi('/api/video', 'DELETE', null, {
        headers: {
          'x-user-id': sessionStorage.getItem('userId') || 'dummy-user',
          'Content-Type': 'application/json'
        },
        query: { id }
      });
      showNotification('Video deleted successfully', 'success');
      loadVideoTasks();
    } catch (e) {
      showNotification(`Delete failed: ${e.message}`, 'error');
    }
  }

  async function handleAskGrok() {
    setIsGeneratingPrompt(true);
    try {
      const payload = {
        prompt: 'Generate a prompt for a 5-second video with optional image context.'
        // Remove image for now; add multer on server if needed later
      };
      const resp = await fetchApi('/api/grok-prompt', 'POST', payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (resp.prompt) {
        const trimmedPrompt = resp.prompt.trim();
        if (trimmedPrompt.length > 2000) {
          showNotification("Grok-generated prompt exceeds 2000 characters and will be truncated", 'warning');
          setPrompt(trimmedPrompt.substring(0, 2000));
        } else {
          setPrompt(trimmedPrompt);
        }
      } else {
        showNotification("No prompt returned from Grok", 'warning');
      }
    } catch (err) {
      showNotification(`Error generating prompt: ${err.message}`, 'error');
    } finally {
      setIsGeneratingPrompt(false);
    }
  }

  useEffect(() => {
    loadVideoTasks();
    const interval = setInterval(() => {
      if (videoTasks.every(task => ['Success', 'Fail'].includes(task.status))) {
        console.log('All video tasks complete, stopping polling');
        clearInterval(interval);
      } else {
        loadVideoTasks();
      }
    }, 120000);
    return () => clearInterval(interval);
  }, []);
  /*
  useEffect(() => {
    loadVideoTasks();
    const interval = setInterval(loadVideoTasks, 120000); // Poll every 30 seconds
    return () => clearInterval(interval);
  }, [loadVideoTasks]);*/

  return (
    <div style={{ marginBottom: '50px' }}>
      <Paper sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#1e1e1e', color: '#e0e0e0' }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#e0e0e0' }}>
          Video Generation <Tooltip title="Use this section to create dynamic videos using Minimax’s video generation API. Provide a prompt, optional image for the first frame (I2V-01, I2V-01-live) or subject (S2V-01), and model settings." placement="top">
            <span style={{ cursor: 'help', fontSize: '0.8em', color: '#90caf9' }}>(?)</span>
          </Tooltip>
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ color: '#e0e0e0' }}>
          Create dynamic videos with Minimax’s video_generation API. Videos will automatically update as they process. Optionally provide a first frame image or subject reference based on the selected model.
        </Typography>

        <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Prompt"
              multiline
              rows={3}
              fullWidth
              value={prompt}
              onChange={e => setPrompt(e.target.value)}
              sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
              helperText="Enter a detailed description for your video (max 2000 characters). Use natural language and optional camera movement instructions like [Pan left] for T2V-01-Director."
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="file"
              inputProps={{ accept: 'image/*' }}
              onChange={e => setGrokImage(e.target.files[0] || null)}
              fullWidth
              sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
              helperText="Optional: Upload an image to inspire the Grok-generated prompt (JPG, JPEG, PNG, < 20MB)."
            />
            <Button
              variant="outlined"
              onClick={handleAskGrok}
              disabled={isGeneratingPrompt}
              sx={{ marginBottom: '10px', color: '#e0e0e0', borderColor: '#424242' }}
            >
              {isGeneratingPrompt ? 'Generating...' : 'Ask Grok'}
            </Button>
          </Grid>
        </Grid>

        <Select
          label="Model"
          value={model}
          onChange={e => setModel(e.target.value)}
          fullWidth
          sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
          helperText="Choose the model: T2V-01 for text-to-video, T2V-01-Director for camera motions, S2V-01 for subject-based, I2V-01/I2V-01-live for image-to-video."
        >
          <MenuItem value="T2V-01">T2V-01 (Text-to-video, no images)</MenuItem>
          <MenuItem value="T2V-01-Director">T2V-01-Director (Text-to-video with camera motions, no images)</MenuItem>
          <MenuItem value="S2V-01">S2V-01 (Subject-based video, requires subject reference image)</MenuItem>
          <MenuItem value="I2V-01">I2V-01 (Image-to-video, requires first frame image)</MenuItem>
          <MenuItem value="I2V-01-live">I2V-01-live (Image-to-video for Live2D/animations, requires first frame image)</MenuItem>
        </Select>
        <FormControlLabel
          control={
            <Checkbox
              checked={promptOptimizer}
              onChange={e => setPromptOptimizer(e.target.checked)}
            />
          }
          label="Prompt Optimizer"
          sx={{ color: '#e0e0e0' }}
          helperText="Enable to let the model optimize the prompt for better results (recommended for most cases)."
        />
        <TextField
          label="First Frame Image (URL/base64) [Optional]"
          fullWidth
          value={firstFrameUrl}
          onChange={e => setFirstFrameUrl(e.target.value)}
          sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
          helperText="Provide a URL or base64-encoded image (JPG, JPEG, PNG, aspect ratio 2:5–5:2, >300px short side, <20MB) as the starting frame for I2V-01 or I2V-01-live."
          disabled={['T2V-01', 'T2V-01-Director', 'S2V-01'].includes(model)}
        />
        <TextField
          label="Subject Reference [Optional]"
          fullWidth
          value={subjectRef}
          onChange={e => setSubjectRef(e.target.value)}
          sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
          helperText="For S2V-01, provide a URL or base64-encoded image (JPG, JPEG, PNG, <20MB) of a human face as the subject. Use only one image."
          disabled={model !== 'S2V-01'}
        />

        <Button variant="contained" onClick={createVideoTask} sx={{ marginRight: '10px' }}>
          Create Video Task
        </Button>
      </Paper>

      <Paper sx={{ padding: '20px', backgroundColor: '#1e1e1e', color: '#e0e0e0', marginTop: '20px' }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#e0e0e0' }}>
          My Video Tasks <Tooltip title="View and manage your generated video tasks here. Videos update automatically and can be deleted." placement="top">
            <span style={{ cursor: 'help', fontSize: '0.8em', color: '#90caf9' }}>(?)</span>
          </Tooltip>
        </Typography>
        {videoTasks.map(item => (
          <div key={item.id} style={{ border: '1px solid #424242', padding: '10px', marginBottom: '10px' }}>
            <Typography variant="subtitle2" sx={{ color: '#e0e0e0' }}>
              <strong>Task ID:</strong> {item.taskId}, <strong>Status:</strong> {item.status}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: '5px', color: '#e0e0e0' }}>
              <em>Model:</em> {item.model}, <em>Prompt:</em> {item.prompt}
            </Typography>
            {item.url && item.status === 'Success' && (
              <video src={item.url} controls style={{ width: '300px' }} />
            )}
            <div style={{ marginTop: '5px' }}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => deleteVideo(item.id)}
                sx={{ color: '#e0e0e0', borderColor: '#424242' }}
              >
                Delete
              </Button>
            </div>
          </div>
        ))}
      </Paper>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
}