import React, { useState } from 'react';
import { Paper, Typography, Button, TextField, Select, MenuItem, Grid, Tooltip, Alert, Snackbar } from '@mui/material';
import WaveSurferPlayer from './WaveSurferPlayer';
import { fetchApi } from '../services/api';

export default function MusicPage() {
  const [lyrics, setLyrics] = useState("");
  const [referVoice, setReferVoice] = useState("");
  const [referInstrumental, setReferInstrumental] = useState("");
  const [sampleRate, setSampleRate] = useState(44100);
  const [bitrate, setBitrate] = useState(256000);
  const [format, setFormat] = useState("mp3");
  const [musicAudioUrl, setMusicAudioUrl] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadPurpose, setUploadPurpose] = useState("song");
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });

  const showNotification = (message, severity = 'info') => {
    setNotification({ open: true, message, severity });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  async function generateMusic() {
    try {
      const payload = {
        refer_voice: referVoice,
        refer_instrumental: referInstrumental,
        lyrics,
        model: 'music-01',
        audio_setting: {
          sample_rate: sampleRate,
          bitrate,
          format
        }
      };
      const resp = await fetchApi('/api/music', 'POST', payload);
      setMusicAudioUrl(resp.audioUrl);
      showNotification('Music generated successfully', 'success');
    } catch (err) {
      showNotification(`Music error: ${err.message}`, 'error');
    }
  }

  async function uploadMusicReference() {
    if (!uploadFile) {
      showNotification("Please select a file first", 'warning');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('file', uploadFile);
      formData.append('purpose', uploadPurpose);

      const res = await fetch('/api/music_upload', {
        method: 'POST',
        body: formData
      });
      const data = await res.json();
      if (data.error) {
        showNotification(`Music upload error: ${JSON.stringify(data)}`, 'error');
      } else {
        showNotification(`Music reference uploaded. ID: ${data.id}`, 'success');
      }
    } catch (err) {
      showNotification(`Upload error: ${err.message}`, 'error');
    }
  }

  return (
    <div>
      <Paper sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#1e1e1e', color: '#e0e0e0' }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#e0e0e0' }}>
          Upload Music Reference <Tooltip title="Upload an audio file to use as a reference for music generation (song, voice, or instrumental)." placement="top">
            <span style={{ cursor: 'help', fontSize: '0.8em', color: '#90caf9' }}>(?)</span>
          </Tooltip>
        </Typography>
        <TextField
          type="file"
          inputProps={{ accept: 'audio/*' }}
          onChange={e => setUploadFile(e.target.files[0] || null)}
          fullWidth
          sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
          helperText="Upload an audio file (MP3, WAV, etc., <20MB) as a reference for song, voice, or instrumental."
        />
        <Select
          value={uploadPurpose}
          onChange={e => setUploadPurpose(e.target.value)}
          fullWidth
          sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
          helperText="Choose the purpose of the audio reference (song, voice, or instrumental)."
        >
          <MenuItem value="song">Song (acapella + accompaniment)</MenuItem>
          <MenuItem value="voice">Voice (only acapella)</MenuItem>
          <MenuItem value="instrumental">Instrumental (only accompaniment)</MenuItem>
        </Select>
        <Button variant="contained" onClick={uploadMusicReference}>Upload Music Reference</Button>
      </Paper>

      <Paper sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#1e1e1e', color: '#e0e0e0' }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#e0e0e0' }}>
          Music Generation <Tooltip title="Generate music using lyrics and optional reference voice or instrumental. Adjust audio settings for quality." placement="top">
            <span style={{ cursor: 'help', fontSize: '0.8em', color: '#90caf9' }}>(?)</span>
          </Tooltip>
        </Typography>
        <TextField
          label="Lyrics"
          multiline
          rows={3}
          fullWidth
          sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
          value={lyrics}
          onChange={e => setLyrics(e.target.value)}
          helperText="Enter lyrics for music generation (any length, but concise lyrics work best)."
        />
        <TextField
          label="Reference Voice ID"
          fullWidth
          sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
          value={referVoice}
          onChange={e => setReferVoice(e.target.value)}
          helperText="Optional: Enter a voice ID from a previous upload for voice reference."
        />
        <TextField
          label="Reference Instrumental ID"
          fullWidth
          sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
          value={referInstrumental}
          onChange={e => setReferInstrumental(e.target.value)}
          helperText="Optional: Enter an instrumental ID from a previous upload for instrumental reference."
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <label style={{ color: '#e0e0e0' }}>Sample Rate:</label>
            <Select
              value={sampleRate}
              onChange={e => setSampleRate(parseInt(e.target.value, 10))}
              fullWidth
              sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
              helperText="Choose the audio sample rate for quality (higher = better quality, larger file)."
            >
              <MenuItem value="44100">44100</MenuItem>
              <MenuItem value="32000">32000</MenuItem>
              <MenuItem value="24000">24000</MenuItem>
              <MenuItem value="16000">16000</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <label style={{ color: '#e0e0e0' }}>Bitrate:</label>
            <Select
              value={bitrate}
              onChange={e => setBitrate(parseInt(e.target.value, 10))}
              fullWidth
              sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
              helperText="Set the audio bitrate for quality (higher = better quality, larger file)."
            >
              <MenuItem value="256000">256000</MenuItem>
              <MenuItem value="128000">128000</MenuItem>
              <MenuItem value="64000">64000</MenuItem>
              <MenuItem value="32000">32000</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <label style={{ color: '#e0e0e0' }}>Format:</label>
            <Select
              value={format}
              onChange={e => setFormat(e.target.value)}
              fullWidth
              sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
              helperText="Select the audio output format (MP3 recommended for compatibility)."
            >
              <MenuItem value="mp3">mp3</MenuItem>
              <MenuItem value="wav">wav</MenuItem>
              <MenuItem value="pcm">pcm</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Button variant="contained" onClick={generateMusic}>Generate Music</Button>
      </Paper>

      {musicAudioUrl && (
        <Paper sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#1e1e1e', color: '#e0e0e0' }}>
          <Typography variant="h6" gutterBottom sx={{ color: '#e0e0e0' }}>
            Music Preview <Tooltip title="Listen to the generated music preview here. Use the controls to play, pause, or stop." placement="top">
              <span style={{ cursor: 'help', fontSize: '0.8em', color: '#90caf9' }}>(?)</span>
            </Tooltip>
          </Typography>
          <WaveSurferPlayer
            audioUrl={musicAudioUrl}
            waveColor="#27ae60"
            progressColor="#1e8449"
          />
          <audio src={musicAudioUrl} controls style={{ width: '100%' }} />
        </Paper>
      )}

      <Paper sx={{ padding: '20px', backgroundColor: '#1e1e1e', color: '#e0e0e0', marginTop: '20px' }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#e0e0e0' }}>
          Music Library <Tooltip title="View and manage your generated music files here. Files can be played or deleted." placement="top">
            <span style={{ cursor: 'help', fontSize: '0.8em', color: '#90caf9' }}>(?)</span>
          </Tooltip>
        </Typography>
        {/* Add music library here if implemented; for now, placeholder */}
      </Paper>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
}