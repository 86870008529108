import React, { useEffect, useState } from 'react';
import { Paper, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Alert, Snackbar } from '@mui/material';
import { fetchApi } from '../services/api';

export default function LibraryTTS({ refreshTrigger }) {
  const [ttsFiles, setTtsFiles] = useState([]);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });

  const showNotification = (message, severity = 'info') => {
    setNotification({ open: true, message, severity });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  useEffect(() => {
    loadTts();
  }, [refreshTrigger]);

  async function loadTts() {
    try {
      const resp = await fetchApi('/api/audio', 'GET');
      let tts = resp.audio.filter(x => x.type === 'TTS');
      tts.sort((a, b) => b.createdAt - a.createdAt);
      setTtsFiles(tts);
    } catch (err) {
      showNotification(`Error loading TTS library: ${err.message}`, 'error');
    }
  }

  async function handleDelete(id) {
    if (!window.confirm("Are you sure you want to delete this TTS file?")) return;
    try {
      const res = await fetchApi(`/api/audio/${id}`, 'DELETE');
      showNotification('TTS file deleted successfully', 'success');
      loadTts();
    } catch (err) {
      showNotification(`Delete error: ${err.message}`, 'error');
    }
  }

  return (
    <Paper sx={{ padding: '20px', backgroundColor: '#1e1e1e', color: '#e0e0e0' }}>
      <Typography variant="h6" gutterBottom sx={{ color: '#e0e0e0' }}>
        TTS Library <Tooltip title="View, play, and delete your generated TTS audio files here. Files are sorted by creation date." placement="top">
          <span style={{ cursor: 'help', fontSize: '0.8em', color: '#90caf9' }}>(?)</span>
        </Tooltip>
      </Typography>
      <Table sx={{ backgroundColor: '#1e1e1e' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#e0e0e0' }}>Summary</TableCell>
            <TableCell sx={{ color: '#e0e0e0' }}>Play</TableCell>
            <TableCell sx={{ color: '#e0e0e0' }}>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ttsFiles.map(item => {
            const summary = `Voice: ${item.voice}, Text: ${item.text.substring(0, 40)}...`;
            return (
              <TableRow key={item.id}>
                <TableCell sx={{ color: '#e0e0e0' }}>{summary}</TableCell>
                <TableCell>
                  <audio src={item.url} controls style={{ width: '120px' }} />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDelete(item.id)}
                    sx={{ color: '#e0e0e0', borderColor: '#424242' }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}