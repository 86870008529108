import React from 'react';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import TTSPage from './components/TTSPage';
import MusicPage from './components/MusicPage';
import LoginPage from './components/LoginPage';
import VideoPage from './components/VideoPage';

export default function App() {
  return (
    <BrowserRouter>
      <AppBar position="static" sx={{ backgroundColor: '#1e1e1e' }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#e0e0e0' }}>
            Sound-side Design
          </Typography>
          <NavLink
            to="/login"
            style={{ color: '#e0e0e0', marginRight: '1rem', textDecoration: 'none' }}
          >
            Login
          </NavLink>
          <NavLink
            to="/tts"
            style={{ color: '#e0e0e0', marginRight: '1rem', textDecoration: 'none' }}
          >
            TTS
          </NavLink>
          <NavLink
            to="/music"
            style={{ color: '#e0e0e0', marginRight: '1rem', textDecoration: 'none' }}
          >
            Music
          </NavLink>
          <NavLink
            to="/video"
            style={{ color: '#e0e0e0', textDecoration: 'none' }}
          >
            Video
          </NavLink>
        </Toolbar>
      </AppBar>

      <Box sx={{ margin: '20px auto', maxWidth: '1100px', padding: '0 20px', backgroundColor: '#121212', color: '#e0e0e0' }}>
        <Routes>
          <Route path="/" element={<TTSPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/tts" element={<TTSPage />} />
          <Route path="/music" element={<MusicPage />} />
          <Route path="/video" element={<VideoPage />} />
        </Routes>
      </Box>
    </BrowserRouter>
  );
}