const apiUrl = process.env.NODE_ENV === 'production' ? 'https://ssd-api-ivory.vercel.app' : 'http://localhost:5007';

export async function fetchApi(endpoint, method = 'POST', body = null, options = {}) {
  const fullUrl = `${apiUrl}${endpoint}`;
  console.log(`Fetching: ${fullUrl}`); // Debug log
  const config = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...options.headers,
      'x-user-id': sessionStorage.getItem('userId'),
    },
    ...options
  };
  if (body) {
    config.body = JSON.stringify(body);
  }
  try {
    const res = await fetch(fullUrl, config);
    if (!res.ok) {
      const text = await res.text();
      throw new Error(`API error: ${res.status} - ${text}`);
    }
    return res.json();
  } catch (err) {
    console.error('API request failed:', err);
    throw err;
  }
}