import React, { useEffect, useState } from 'react';
import { Paper, Typography, Button, TextField, Select, MenuItem } from '@mui/material';
import { fetchApi } from '../services/api';

export default function VoiceManager({ selectedVoice, onSelectVoice }) {
  const [voices, setVoices] = useState([]);
  const [tab, setTab] = useState('clone');

  const [cloneFile, setCloneFile] = useState(null);
  const [cloneId, setCloneId] = useState("");

  const [blendVoiceA, setBlendVoiceA] = useState("");
  const [blendVoiceB, setBlendVoiceB] = useState("");
  const [blendWeightA, setBlendWeightA] = useState(50);
  const [blendWeightB, setBlendWeightB] = useState(50);
  const [blendName, setBlendName] = useState("");

  useEffect(() => {
    loadVoices();
  }, []);

  async function loadVoices() {
    try {
      const resp = await fetchApi('/api/voices', 'GET');
      setVoices(resp.voices);
    } catch (err) {
      alert("Load voices error: " + err.message);
    }
  }

  async function handleClone() {
    if (!cloneFile || !cloneId) {
      alert("Select file + voice ID");
      return;
    }
    try {
      const formData = new FormData();
      formData.append('file', cloneFile);
      formData.append('voice_id', cloneId);
      const res = await fetch('/api/clone', { method: 'POST', body: formData });
      const data = await res.json();
      if (data.base_resp && data.base_resp.status_code === 0) {
        alert(`Cloned voice: ${cloneId}`);
        loadVoices();
      } else {
        alert("Error: " + JSON.stringify(data));
      }
    } catch (err) {
      alert("Clone error: " + err.message);
    }
  }

  async function handleBlend() {
    if (!blendVoiceA || !blendVoiceB || !blendName) {
      alert("Select voices + blend name");
      return;
    }
    if (blendWeightA + blendWeightB !== 100) {
      alert("Weights must sum to 100%");
      return;
    }
    try {
      const resp = await fetchApi('/api/blend', 'POST', {
        name: blendName,
        components: [
          { voice: blendVoiceA, weight: blendWeightA },
          { voice: blendVoiceB, weight: blendWeightB }
        ]
      });
      if (resp.status === 'success') {
        alert(`Blended voice: ${blendName}`);
        loadVoices();
      } else {
        alert("Blend error: " + JSON.stringify(resp));
      }
    } catch (err) {
      alert("Blend error: " + err.message);
    }
  }

  return (
    <Paper sx={{ padding: '10px', marginBottom: '20px', backgroundColor: '#1e1e1e', color: '#e0e0e0' }}>
      <Typography variant="h6" gutterBottom sx={{ color: '#e0e0e0' }}>Voice Manager</Typography>
      <Typography variant="body2" gutterBottom sx={{ color: '#e0e0e0' }}>
        Select an existing voice or create a new one.
      </Typography>
      <label style={{ color: '#e0e0e0' }}>Voice:</label>
      <Select
        fullWidth
        sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
        value={selectedVoice}
        onChange={e => onSelectVoice(e.target.value)}
      >
        <MenuItem value="">-- select voice --</MenuItem>
        {voices.map(v => (
          <MenuItem key={v.name} value={v.name}>{v.name} ({v.type})</MenuItem>
        ))}
      </Select>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
        <Button
          variant={tab === 'clone' ? 'contained' : 'outlined'}
          onClick={() => setTab('clone')}
          sx={{ color: '#e0e0e0', borderColor: '#424242' }}
        >
          Clone
        </Button>
        <Button
          variant={tab === 'blend' ? 'contained' : 'outlined'}
          onClick={() => setTab('blend')}
          sx={{ color: '#e0e0e0', borderColor: '#424242' }}
        >
          Blend
        </Button>
      </div>
      {tab === 'clone' && (
        <div style={{ marginBottom: '10px' }}>
          <TextField
            type="file"
            fullWidth
            inputProps={{ accept: 'audio/*' }}
            onChange={e => setCloneFile(e.target.files[0] || null)}
            sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
          />
          <TextField
            label="Voice ID"
            placeholder="e.g. MyVoice001"
            fullWidth
            value={cloneId}
            onChange={e => setCloneId(e.target.value)}
            sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
          />
          <Button variant="contained" onClick={handleClone}>Clone Voice</Button>
        </div>
      )}
      {tab === 'blend' && (
        <div style={{ marginBottom: '10px' }}>
          <label style={{ color: '#e0e0e0' }}>Voice A:</label>
          <Select
            fullWidth
            sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
            value={blendVoiceA}
            onChange={e => {
              setBlendVoiceA(e.target.value);
            }}
          >
            <MenuItem value="">-- select --</MenuItem>
            {voices.map(v => (
              <MenuItem key={v.name} value={v.name}>{v.name}</MenuItem>
            ))}
          </Select>
          <TextField
            label="Weight A (%)"
            type="number"
            fullWidth
            sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
            value={blendWeightA}
            onChange={e => {
              const val = parseInt(e.target.value, 10);
              setBlendWeightA(val);
              setBlendWeightB(100 - val);
            }}
          />
          <label style={{ color: '#e0e0e0' }}>Voice B:</label>
          <Select
            fullWidth
            sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
            value={blendVoiceB}
            onChange={e => {
              setBlendVoiceB(e.target.value);
            }}
          >
            <MenuItem value="">-- select --</MenuItem>
            {voices.map(v => (
              <MenuItem key={v.name} value={v.name}>{v.name}</MenuItem>
            ))}
          </Select>
          <TextField
            label="Weight B (%)"
            type="number"
            fullWidth
            sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
            value={blendWeightB}
            onChange={e => {
              const val = parseInt(e.target.value, 10);
              setBlendWeightB(val);
              setBlendWeightA(100 - val);
            }}
          />
          <TextField
            label="Blended Voice Name"
            fullWidth
            sx={{ marginBottom: '10px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
            value={blendName}
            onChange={e => setBlendName(e.target.value)}
          />
          <Button variant="contained" onClick={handleBlend}>Create Blend</Button>
        </div>
      )}
    </Paper>
  );
}