import React, { useState, useEffect } from 'react';
import { Paper, Button, Typography, TextField, Select, MenuItem, FormControlLabel, Checkbox, Grid, Tooltip, Alert, Snackbar } from '@mui/material';
import WaveSurferPlayer from './WaveSurferPlayer';
import VoiceManager from './VoiceManager';
import LibraryTTS from './LibraryTTS';
import { fetchApi } from '../services/api';

export default function TTSPage() {
  const getSaved = key => sessionStorage.getItem(key) || '';
  const getSavedNum = (key, def) => Number(sessionStorage.getItem(key)) || def;

  const [text, setText] = useState(getSaved('tts_text') || "Enter text here...");
  const [model, setModel] = useState(getSaved('tts_model') || "speech-01-hd");
  const [speed, setSpeed] = useState(getSavedNum('tts_speed', 1.0));
  const [volume, setVolume] = useState(getSavedNum('tts_volume', 3));
  const [pitch, setPitch] = useState(getSavedNum('tts_pitch', 1));
  const [emotion, setEmotion] = useState(getSaved('tts_emotion') || "neutral");
  const [subtitleEnable, setSubtitleEnable] = useState(getSaved('tts_subtitle') === 'true' || true);
  const [selectedVoice, setSelectedVoice] = useState(getSaved('tts_voice') || ''); // Default to empty string
  const [audioUrl, setAudioUrl] = useState("");
  const [refreshLib, setRefreshLib] = useState(0);
  const [voices, setVoices] = useState([]);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });

  const showNotification = (message, severity = 'info') => {
    setNotification({ open: true, message, severity });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  useEffect(() => {
    sessionStorage.setItem('tts_text', text);
    sessionStorage.setItem('tts_model', model);
    sessionStorage.setItem('tts_speed', speed);
    sessionStorage.setItem('tts_volume', volume);
    sessionStorage.setItem('tts_pitch', pitch);
    sessionStorage.setItem('tts_emotion', emotion);
    sessionStorage.setItem('tts_subtitle', subtitleEnable);
    sessionStorage.setItem('tts_voice', selectedVoice);
  }, [text, model, speed, volume, pitch, emotion, subtitleEnable, selectedVoice]);

  useEffect(() => {
    async function loadVoices() {
      try {
        const resp = await fetchApi('/api/voices', 'GET');
        setVoices(resp.voices);
        // If the saved voice isn't in the list, reset to empty
        if (selectedVoice && !resp.voices.some(v => v.name === selectedVoice)) {
          setSelectedVoice('');
        }
      } catch (err) {
        showNotification(`Failed to load voices: ${err.message}`, 'error');
      }
    }
    loadVoices();
  }, [selectedVoice]);

  async function handleGenerate() {
    if (!selectedVoice || selectedVoice === '') {
      showNotification("Please select a voice before generating TTS", 'warning');
      return;
    }

    try {
      const payload = {
        model,
        text,
        stream: false,
        subtitle_enable: subtitleEnable,
        voice_setting: {
          voice_id: selectedVoice,
          speed,
          vol: volume,
          pitch,
          emotion
        },
        custom_voice: selectedVoice,
        audio_setting: {
          sample_rate: 32000,
          bitrate: 128000,
          format: 'mp3',
          channel: 1
        }
      };
      const resp = await fetchApi('/api/tts', 'POST', payload);
      setAudioUrl(resp.audioUrl);
      setRefreshLib(r => r + 1);
      showNotification('TTS generated successfully', 'success');
    } catch (err) {
      showNotification(`TTS Error: ${err.message}`, 'error');
    }
  }

  return (
    <div>
      <Paper sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#1e1e1e', color: '#e0e0e0' }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#e0e0e0' }}>
          Text-to-Speech <Tooltip title="Generate audio from text using Minimax’s t2a_v2 API. Adjust settings for voice, speed, and emotion for customized output." placement="top">
            <span style={{ cursor: 'help', fontSize: '0.8em', color: '#90caf9' }}>(?)</span>
          </Tooltip>
        </Typography>
        <TextField
          label="Text"
          multiline
          rows={4}
          fullWidth
          value={text}
          onChange={e => setText(e.target.value)}
          sx={{ marginBottom: '20px', input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
          helperText="Enter the text you want to convert to speech (any length, but long texts may take longer to process)."
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Select
              value={model}
              onChange={e => setModel(e.target.value)}
              fullWidth
              size="small"
              sx={{ input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
              helperText="Select the speech model for quality and speed trade-offs."
            >
              <MenuItem value="speech-01-hd">speech-01-hd (High Quality)</MenuItem>
              <MenuItem value="speech-01-turbo">speech-01-turbo (Fast)</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Speed"
              type="number"
              fullWidth
              size="small"
              value={speed}
              onChange={e => setSpeed(parseFloat(e.target.value))}
              sx={{ input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
              helperText="Adjust speech speed (0.5–2.0, default 1.0)."
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Volume"
              type="number"
              fullWidth
              size="small"
              value={volume}
              onChange={e => setVolume(parseFloat(e.target.value))}
              sx={{ input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
              helperText="Set volume level (0–10, default 3)."
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Pitch"
              type="number"
              fullWidth
              size="small"
              value={pitch}
              onChange={e => setPitch(parseInt(e.target.value, 10))}
              sx={{ input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
              helperText="Adjust pitch (-12 to 12, default 1)."
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Select
              value={emotion}
              onChange={e => setEmotion(e.target.value)}
              fullWidth
              size="small"
              sx={{ input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
              helperText="Choose the emotional tone for the voice."
            >
              <MenuItem value="neutral">Neutral</MenuItem>
              <MenuItem value="happy">Happy</MenuItem>
              <MenuItem value="sad">Sad</MenuItem>
              <MenuItem value="angry">Angry</MenuItem>
              <MenuItem value="fearful">Fearful</MenuItem>
              <MenuItem value="disgusted">Disgusted</MenuItem>
              <MenuItem value="surprised">Surprised</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={<Checkbox checked={subtitleEnable} onChange={e => setSubtitleEnable(e.target.checked)} />}
              label="Subtitles"
              labelPlacement="end"
              sx={{ color: '#e0e0e0' }}
              helperText="Enable to generate subtitle data (useful for accessibility)."
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
          <Grid item xs={12} sm={4}>
            <Select
              fullWidth
              size="small"
              value={selectedVoice}
              displayEmpty
              onChange={e => setSelectedVoice(e.target.value)}
              sx={{ input: { color: '#e0e0e0' }, label: { color: '#e0e0e0' }, fieldset: { borderColor: '#424242' } }}
              helperText="Select a voice from built-in or custom options for TTS generation."
            >
              <MenuItem value="">-- select voice --</MenuItem>
              {voices.map(v => (
                <MenuItem key={v.name} value={v.name}>
                  {v.name} ({v.type})
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="contained" onClick={handleGenerate} fullWidth>Generate TTS Audio</Button>
          </Grid>
        </Grid>
      </Paper>

      {audioUrl && (
        <Paper sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#1e1e1e', color: '#e0e0e0' }}>
          <Typography variant="h6" gutterBottom sx={{ color: '#e0e0e0' }}>
            Preview <Tooltip title="Listen to the generated audio preview here. Use the controls to play, pause, or stop." placement="top">
              <span style={{ cursor: 'help', fontSize: '0.8em', color: '#90caf9' }}>(?)</span>
            </Tooltip>
          </Typography>
          <WaveSurferPlayer audioUrl={audioUrl} waveColor="#2980b9" progressColor="#1c5980" />
          <audio src={audioUrl} controls style={{ width: '100%' }} />
        </Paper>
      )}

      <VoiceManager selectedVoice={selectedVoice} onSelectVoice={setSelectedVoice} />

      <LibraryTTS refreshTrigger={refreshLib} />

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
}