import React, { useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';

export default function WaveSurferPlayer({ audioUrl, waveColor, progressColor }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

  useEffect(() => {
    if (waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: waveColor || '#2980b9',
        progressColor: progressColor || '#1c5980',
        height: 100,
        responsive: true,
        barWidth: 2,
        barGap: 1
      });

      wavesurfer.current.load(audioUrl);

      return () => wavesurfer.current.destroy();
    }
  }, [audioUrl, waveColor, progressColor]);

  return (
    <div style={{ backgroundColor: '#1e1e1e', padding: '10px', borderRadius: '4px' }}>
      <div ref={waveformRef} />
      <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
        <button onClick={() => wavesurfer.current.playPause()}>
          {wavesurfer.current && wavesurfer.current.isPlaying() ? 'Pause' : 'Play'}
        </button>
        <button onClick={() => wavesurfer.current.stop()}>
          Stop
        </button>
      </div>
    </div>
  );
}